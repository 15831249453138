import React from 'react'
import { Container } from 'components/common'
import AttractCustomers from 'assets/illustrations/attract-customers.svg'
import GradientElement from "../../../assets/elements/element-01.svg"
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'

export const Work = () => (
	<Wrapper>
		<IntroWrapper as={Container}>
			<Details>
				<h1>Your New Growth Partner</h1>
				<img src={GradientElement} alt="title break element" className="element-gradient" />
				<h4>We turn your online data points into stories about
				 your business that resonate with your customers online & offline.
				</h4>
			</Details>
			<Thumbnail>
				<img src={AttractCustomers} alt="Outsourced Marketing" />
			</Thumbnail>
		</IntroWrapper>
	</Wrapper>
)