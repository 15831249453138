import React from 'react'
import { Link } from 'gatsby'
import { Header } from 'components/theme'
import { Container, Button } from 'components/common'
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'

import GradientElement from "../../../assets/elements/element-01.svg"

export const Intro = () => (
	<Wrapper>
		<Header />
		<IntroWrapper as={Container}>
			<Details>
				<h1>Great Marketing
				Is Tough,
				That’s Why
				We’re Here.</h1>
				<img src={GradientElement} alt="title break element" className="element-gradient" />
				<h4>Think of us as your personal command center 
				that’s focused on your business goals & success.
				</h4>
				<Link to="/contact">
					<Button>
						Grow With Us
					</Button>
				</Link>
			</Details>
			<Thumbnail className="mobile-hero">
				<br />
			</Thumbnail>
		</IntroWrapper>
	</Wrapper>
)
