import React from 'react'
import { Container } from 'components/common'
import { Wrapper, Details } from './styles'
import GetForm from './GetForm'

export const Contact = () => (
	<Wrapper as={Container} id="contact">
		<Details>
			<h1>Request a Free Consultation Today</h1>
			<GetForm />
		</Details>
	</Wrapper>
)