import React from 'react'
import { Container } from 'components/common'
import Taqueros from '../../../assets/illustrations/Illustration-Taqueros.svg'
import EMPC from '../../../assets/illustrations/Illustration-ElMontePizzaCo.svg'
import ApolloReserve from '../../../assets/illustrations/Illustration-ApolloReserve.svg'

import { Wrapper, CustomerWrapper, Details, Thumbnail } from './styles'

import GradientElement from "../../../assets/elements/element-01.svg"


export const Customers = () => (
	<Wrapper>
		<div className="center-title">
			<h1>Solutions to keep you moving forward</h1>
			<p>Select clients we have helped recover and thrive since 2019</p>
		</div>

		<CustomerWrapper as={Container}>
			<Thumbnail>
				<img src={Taqueros} alt="Pandemic Sales Recovery" />
			</Thumbnail>
			<Details>
				<h1 className="endslate-title">TAQUEROS MEXICAN FOOD</h1>
				<img src={GradientElement} alt="title break element" className="element-gradient" />
				<p>
					<strong>Q1</strong> - Pandemic Revenue Recovery
					<br />
					<strong>Q2</strong> - Online Ordering Sales Growth
					<br />
					<strong>Q3</strong> - Local Market Optimization & Expansion
					<br />
					<strong>Q4</strong> - New Store Launched in Mission Viejo, CA
				</p>
			</Details>
		</CustomerWrapper>

		<CustomerWrapper as={Container}>

			<Details>
				<h1 className="endslate-title">EL MONTE PIZZA CO</h1>
				<img src={GradientElement} alt="title break element" className="element-gradient" />
				<p>Starting a business in late 2020 meant reduced food traffic, and taking their marketing 
				online our team grew revenue by <strong>63% in 30-days</strong>. Now a staple for handmade 
				artisan pizza.</p>
			</Details>

			<Thumbnail>
				<img src={EMPC} alt="eCommerce For Restaurants" className="resize-img" />
			</Thumbnail>

		</CustomerWrapper>

		<CustomerWrapper as={Container}>

			<Thumbnail>
				<img src={ApolloReserve} alt="eCommerce For Restaurants" className="resize-img" />
			</Thumbnail>

			<Details>
				<h1 className="endslate-title">APOLLO RESERVE</h1>
				<img src={GradientElement} alt="title break element" className="element-gradient" />
				<p>
					<strong>-</strong> Product Development & Branding
					<br />
					<strong>-</strong> 360 Online Marketing Strategy
					<br />
					<strong>-</strong> Shopify Setup, Design & Development
					<br />
					<strong>-</strong> Instagram, Facebook & Pinterest Shopping
				</p>
			</Details>

		</CustomerWrapper>

	</Wrapper>
)