import React from 'react'
import { Layout, SEO } from 'components/common'
import { Intro, Customers, Skills, Work, Contact } from 'components/landing'

export default () => (
	<Layout>
		<SEO />
		<Intro />
		<Customers />
		<Skills />
		<Work />
		<Contact />
	</Layout>
)
