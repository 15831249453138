import styled from 'styled-components'
import SMBmarketing from '../../../assets/images/SMB-Marketing.png'

export const Wrapper = styled.div`
	background-size: 50% auto;
	background-position: right bottom;
	background-repeat: no-repeat;
	background-image: url(${SMBmarketing});
	// background-color: #031b29;

	.element-gradient {
		max-width: 100px;
	}

	@media (max-width: 1040px) {
		width: 100%;
		// margin-bottom: 2rem;
		background-position: left 400px top 80px;

	}

	@media (max-width: 780px) {
		width: 100%;
		// margin-bottom: 2rem;
		background-size: 0%;

	}

`

export const IntroWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 500px;

	@media (max-width: 960px) {
		flex-direction: column;
	}
`

export const Details = styled.div`
	flex: 1;

	@media (max-width: 959px) {
		width: 100%;
		margin-bottom: 2rem;
	}

	h1 {
		margin-bottom: 2rem;
		font-size: 32pt;
		color: #212121;

		@media (max-width: 680px) {
			font-size: 28pt;
		}
	}

	h4 {
		margin-bottom: 2.5rem;
		font-size: 24pt;
		font-weight: normal;
		color: #707070;

		@media (max-width: 680px) {
			font-size: 20pt;
		}
	}

	/* link decoration*/
	a:link {
		color: white;
	}

	/* visited link */
	a:visited {
		color: #F5F5F5;
	}

	/* mouse over link */
	a:hover {
		color: #DCDCDC;
	}

	/* selected link */
	a:active {
		color: #707070;
	}
`

export const Thumbnail = styled.div`
	flex: 1;
	// background-image: url(${SMBmarketing});
	background-position: top left;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 500px;
	


	@media (max-width: 760px) {
		width: 100%;
		background-size: auto;
		background-position: right top;
		background-repeat: no-repeat;
		background-image: url(${SMBmarketing});
	}

	img {
		width: 100%;
	}
`