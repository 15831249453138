import styled from 'styled-components'

export const Wrapper = styled.div`
	background-size: contain;
	background-position: left top;
	background-repeat: no-repeat;
	background-color: #F7F7F7;

	.endslate-title {
		color: #50FFB4;
	}
 
	.center-title {
		padding: 2.5rem;
		text-align: center;

		p {
		margin-bottom: 2rem;
		font-size: 18pt;
		font-weight: normal;
		line-height: 1.3;
		color: #707070;
		}
	}

	.element-gradient {
		max-width: 100px;
	}

	.element-gradient-wide {
		width: 200px;
		height: 10px;
	}
`

export const CustomerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 6rem;

	@media (max-width: 960px) {
		flex-direction: column;
	}
`

export const Details = styled.div`
	flex: 1;
	padding-left: 2rem;

	@media (max-width: 960px) {
		padding-left: unset;
		width: 100%;
	}

	h1 {
		margin-bottom: 2rem;
		font-size: 26pt;
		color: #212121;
	}

	p {
		margin-bottom: 2.5rem;
		font-size: 20pt;
		font-weight: normal;
		line-height: 1.3;
		color: #707070;
	}
`

export const Thumbnail = styled.div`
	flex: 1;

	@media (max-width: 960px) {
		width: 100%;
		margin-bottom: 2rem;
	}

	img {
		width: 100%;
	}

	.resize-img {
		max-height: 500px;
	}
`
