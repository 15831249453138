import React from 'react'
import { Container } from 'components/common'
import DigitalTeam from 'assets/illustrations/digital-team'
import GradientElement from "../../../assets/elements/element-01.svg"
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

export const Skills = () => (
	<Wrapper>
		<div className="center-title">
			<h1>How Would Your Business Look a 1-Year From Now?</h1>
			<p>In 2020, many business owners were on the fence about taking their 
			business online without certainty. Those that went all in saw their business 
			recover within 30-days, and now online sales is on route to make over 50% of business.</p>
		</div>

		<SkillsWrapper as={Container}>
			<Thumbnail>
				<DigitalTeam alt="Outsourced Digital Team" />
			</Thumbnail>
			<Details>
				<h1>Give Your Business a New Slate</h1>
				<img src={GradientElement} alt="title break element" className="element-gradient" />
				<p>
          		Put an end to your to-do list with a digital team of specialists
           		helping you grow behind the scenes, only pay for the services that you need.
				</p>
			</Details>
		</SkillsWrapper>
	</Wrapper>
)
